import React, { FC } from "react";
import { IRewardCard } from "../../packages/interface/rewards";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";

const UpgradeRewardCard: FC<IRewardCard> = ({
  id,
  brand,
  title,
  image,
  description,
  points,
  setModalData,
  openModal,
  upgradable = false,
}) => {
  return (
    <div className={` shadow  w-full h-max rounded relative `}>
      <div
        data-testid="upgradecard"
        onClick={() => {
          if (setModalData && openModal) {
            setModalData({
              brand,
              title,
              image,
              description,
              points,
              id,
              type: "upgradeCard",
            });
            openModal(true);
          }
        }}
        className="cursor-pointer  group "
      >
        <div className="h-[70%] w-auto rounded">
          <AspectRatio.Root ratio={1}>
            <img src={image} alt={title} className="w-full h-full rounded " />
          </AspectRatio.Root>
        </div>
        <div className="mb-2 h-16 px-2">
          <div className="pt-2">
            <h2 className="text-[#7A8496] uppercase text-xs">{brand}</h2>
          </div>
          <div className="space-y-1">
            <h2 className="text-sm text-black max-text-line-2">{title}</h2>
          </div>
        </div>
        {/* -rotate-[30deg]  -left-20 top-3   */}
        <div className=" absolute top-0  transition-all  h-full lg:h-0 group-hover:h-full right-0 left-0 rounded  bg-[#59149B] opacity-70 overflow-hidden">
          <div className="bg-white opacity-80 absolute -rotate-[30deg] -left-3 top-3  w-28 text-center font-medium text-sm ">
            Upgrade
          </div>
          <div className="h-full  flex items-center  justify-center text-white ">
            <div className="space-y-2 flex flex-col items-center">
              <img src="crownking.png" alt="crownking" className="w-12 h-12" />
              <h2 className="capitalize text-sm font-medium ">
                Upgrade subscription
              </h2>
              <ul className="list-disc text-xs space-y-1 pl-6 pr-2">
                <li>Make your investment count</li>
                <li>Best ROI on 2 year subscription</li>
                <li>Choose your rewards from 10,000 products</li>
                <li>Delight your family while you read</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`pb-2 h-[calc(70%-4rem)] group cursor-auto w-full relative `}
      >
        <div
          className={`text-base flex items-center space-x-2  ${
            upgradable
              ? "group-hover:opacity-0 group-hover:pointer-events-none transition-all"
              : ""
          }`}
        >
          {/* <img src="coinsthree.svg" alt="threecoins" className="h-6" /> */}
          <h3 className="font-medium opacity-0">{points}</h3>
        </div>
        {/* <button
          className={`bg-[#000000] absolute bottom-0 right-0 h-8 w-8 rounded-br rounded-tl flex items-center justify-center ${upgradable ? 'group-hover:rounded-tl-none group-hover:rounded-bl group-hover:w-full duration-300' : ''}  text-white overflow-hidden`}
        >
          <span className={`h-8 w-8 flex items-center justify-center opacity-100 ${upgradable ? 'group-hover:opacity-0 overflow-hidden transition-all duration-200' : ''}`}>
            <div className="opacity-80 px-1">

              <img src="crownicon.svg" alt="crownicon" className="h-8 w-8" />
            </div>
          </span>
          {upgradable ? <span className={`space-x-2 text-white w-max flex items-center absolute left-full group-hover:left-[calc(50%-7.6rem/2)] transition-all `}>
            <span className="h-8 w-8 flex items-center justify-center transition-all">

              <div className="opacity-80 px-1">

                <img src="crownicon.svg" alt="crownicon" className="h-8 w-8" />
              </div>
            </span>
            Upgrade
          </span> : <></>}

        </button> */}
      </div>
    </div>
  );
};

export default UpgradeRewardCard;
