import { IRewards } from './../../packages/interface/rewards';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface cartState {
    cart:IRewards[]
}

const initialState:cartState = {
cart:[]
}


export const cartSlice = createSlice({
    name:"cartSlice",
    initialState,
    reducers:{
        setCart :(state,action : PayloadAction<any>) =>{ 
             if (!(state.cart.length > 0)) {
                 state.cart = action.payload
             }else{
                 state.cart = [...state.cart,...action.payload]
                }
        },
        updateCart : (state,action : PayloadAction<any>) => {
               const sku = action.payload
              const newCart = state.cart.filter((item: any) => item.sku !== sku);
               state.cart = newCart
        }
    }
})


export const {setCart,updateCart} =cartSlice.actions

export default cartSlice.reducer