export const desktopIntroSteps = [
  {
    element: "#balance",
    intro: "Here is your balance",
  },
  {
    element: "#cart",
    intro: "Go to cart",
  },
  {
    element: "#secondcard",
    intro: "Pick any brand",
    position: "right",
  },
  {
    element: "#reedem",
    intro: "First finish your points then Reedem",
    position: "left",
    //   tooltipClass:"introtooltip"
  },
  {
    element: "#trendingbrands",
    intro: "Here is our trending brands",
    //   position:"bottom-right"
  },
  {
    element: "#trendingcategory",
    intro: "Here is our trending category",
  },
];

export const mobileIntroData = [
  {
    element: "#mobilesearchbar",
    intro: "Here you can search rewards",
  },
  {
    element: "#mobilecart",
    intro: "Go to cart",
  },
];
