import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRewardState {
  isRemainingPointsLoading: boolean;
  remainingReward: any[];
  isReedemActive: boolean;
  isRemainingRewwardModal: boolean;
  isCongratsModal: boolean;
}

export const initialState: IRewardState = {
  isRemainingPointsLoading: false,
  remainingReward: [],
  isReedemActive: false,
  isRemainingRewwardModal: false,
  isCongratsModal: false,
};

export const rewardSlice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    setIsRemainingPointsLoading: (state, action: PayloadAction<boolean>) => {
      state.isRemainingPointsLoading = action.payload;
    },
    setIsReedemActive: (state, action: PayloadAction<boolean>) => {
      state.isReedemActive = action.payload;
    },
    setRemainingReward: (state, action: PayloadAction<any[]>) => {
      state.remainingReward = [...action.payload];
    },
    setIsRemainingRewardModal: (state, action: PayloadAction<boolean>) => {
      state.isRemainingRewwardModal = action.payload;
    },
    setIsCongratsModal: (state, action: PayloadAction<boolean>) => {
      state.isCongratsModal = action.payload;
    },
  },
});

export const {
  setIsRemainingPointsLoading,
  setIsReedemActive,
  setRemainingReward,
  setIsRemainingRewardModal,
  setIsCongratsModal,
} = rewardSlice.actions;

export default rewardSlice.reducer;
