import * as Sentry from '@sentry/react'

import Tracker from './Tracker'

export interface IGetSessionData {
  sessionId: string
  userId: string
  userIdSegment: string
  userSubscriptionSegment: string
  subscription: string
  subscriptionDuration: string
}
export interface ISessionData {
  session_id: string,
  user_id: string,
  aud: string,
  subscription: string,
  duration: string,
}
type TGetSessionData = (sessionData: ISessionData) => IGetSessionData

interface ICaptureEventArgs {
  sessionId: string,
  userId: string,
  userSubscriptionSegment: string,
  subscription: string,
  subscriptionDuration: string,
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug',
  transactionName: string,
  message: string
}

type TCaptureEvent = (
  data: ICaptureEventArgs
) => string

export class UserAnalytics {

  constructor() {

  }

  getSessionData: TGetSessionData = (sessionData) => {
    return {
      sessionId: sessionData['session_id'],
      userId: sessionData['user_id'],
      userIdSegment: `${sessionData['user_id']}/${sessionData['aud']}`,
      userSubscriptionSegment: `${sessionData['subscription']}-${sessionData['duration']}`,
      subscription: sessionData['subscription'],
      subscriptionDuration: sessionData['duration']
    }
  }

  setUserInSentry = (
    sessionId: string,
    userIdSegment: string,
    userSubscriptionSegment: string
  ) => {
    Sentry.setUser({
      id: sessionId,
      username: userIdSegment,
      segment: userSubscriptionSegment
    })
  }

  captureEventInSentry: TCaptureEvent = ({
    sessionId,
    userId,
    userSubscriptionSegment,
    subscription,
    subscriptionDuration,
    level = 'info',
    transactionName,
    message
  }) => {
    const eventId = Sentry.captureEvent({
      message: `${sessionId}-${userId}-${userSubscriptionSegment} ${message || ''}`,
      event_id: sessionId,
      extra: {
        subscription: subscription,
        subscriptionDuration: subscriptionDuration
      },
      level: level,
      transaction: transactionName,
      release: process.env.REACT_APP_VERSION,
      type: "transaction"
    })

    return eventId
  }

}

export {
  Tracker
}