import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TokenUser } from "../../context"

export interface IUserState {
    decodedUser:TokenUser,
    orderToken:string,
    error:{ msg: string; isError: boolean }
}

const initialState = {
decodedUser:{range_points:0,redirect_uri:""},
orderToken:"",
error:{
msg: "",
isError: false
}
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setDecodedUser : (state,action:PayloadAction<any>) =>{
             state.decodedUser = action.payload
        },
        setOrderToken : (state,action:PayloadAction<string>) => {
             state.orderToken = action.payload
        },
        setError : (state,action :PayloadAction<{ msg: string; isError: boolean }>) => {
               state.error = action.payload
        }
    }
})


export const {setDecodedUser,setOrderToken,setError} = userSlice.actions

export default userSlice.reducer