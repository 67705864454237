import React, { Dispatch, FC, SetStateAction } from "react";
import { createOrder } from "../../packages/api/cart";
import AccoladezLogo from "../../packages/design/AccoladezLogo";

interface ICartFooter {
  balance: number;
  handleCheckout:any
}

const CartFooter: FC<ICartFooter> = ({
  balance,
  handleCheckout
}) => {
 
  return (
    <section className=" bg-white z-10 fixed fixedhandled bottom-0 left-0 right-0 flex flex-col-reverse sm:flex-row justify-between items-center lg:hidden  ">
      <div className=" px-5 py-3 sm:w-[50%] xl:w-[87%] w-max">
        <h1 className="font-medium  text-filterText text-xs flex items-center whitespace-nowrap space-x-1 w-max">
          Powered by
          <AccoladezLogo className="h-4 ml-1 max-w-[7rem]"/>
         
        </h1>
      </div>
     
       {
        balance === 0 && 
        <button
        onClick={handleCheckout}
        className={` w-full md:w-max text-sm md:text-base text-white bg-[#6ABD00] py-2 px-4 font-medium rounded cursor-pointer`}
      >
        Checkout now
      </button>
       } 
    </section>
  );
};

export default CartFooter;
