import React, { FC, useEffect, useState } from "react";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { useAppContext } from "../../context";

const Notification: FC = (props: any) => {
  const { notification, setNotification, notificationData, setNotificationData } = useAppContext();
  const [isNoty, setIsNoty] = useState<boolean>(false)

  const [timeOutIds, setTimeoutIds] = useState<any[]>([])

  useEffect(() => {

    const interval = setTimeout(() => {
      props.removeNoty(props.id)
    }, 1000)

    if (interval) {
      setTimeoutIds(prev => [...prev, interval])
    }
    return () => clearTimeout(interval)
  }, [notificationData])

  return (
    <div
      className={`${isNoty ? "" : ""} w-96  rounded-md  px-4 pt-2 pb-4 bg-white drop-shadow transition-all  duration-500  z-[500]`}
    >
      <div className="flex items-center justify-center space-x-2 mb-1">
        {props.type === "remove" ? <AiFillDelete className="text-2xl text-red-500" /> : <BsCheck2Circle className="text-green-500 text-3xl " />}


        <p className="text-sm font-medium uppercase ">{props.msg}</p>
      </div>

      <div className="flex items-center space-x-3 ">
        <div className="h-16 w-20">
          <img
            src={props?.data?.image}
            alt="hindu"
            className="h-full w-full"
          />
        </div>
        <div className="space-y-1 ">
          <h2 className="text-xs">{props?.data?.title}</h2>
          <div className="flex space-x-1">
            <div className="h-6 w-6">
              <img
                src="starcoin.png"
                alt="starcoin"
                className="h-full w-full"
              />
            </div>
            <p className="text-sm text-textColor">
              {" "}
              {props?.data?.points || props?.data?.balance}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
