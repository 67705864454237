import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { Tracker } from "../../lib/sentry";
import { createOrder, updateRewardInCart } from "../../packages/api/cart";
import AccoladezLogo from "../../packages/design/AccoladezLogo";
import { IRewards } from "../../packages/interface/rewards";
import { routes } from "../../packages/utils/routes";
import { RootState } from "../../store";
import SingleCartItem from "./SingleCartItem";
interface ICartOrder {
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>;
  handleCheckout: () => void;
  removeRewardFromCart: any;
}

const CartOrders: FC<ICartOrder> = ({
  balance,
  handleCheckout,
  removeRewardFromCart,
}) => {
  const { setNotificationData, setRewardsLoading } = useAppContext();
  const navigate = useNavigate()
  const { cart } = useSelector((state: RootState) => state.cart)

  const [amount, setAmount] = useState<number>(0);

  const tracker = new Tracker();

  useEffect(() => {
    let amount = 0;
    cart.map((item: any) => {
      if (item.remainingpoints) {
        amount += item.remainingpoints;
      } else {
        amount += item.points;
      }
    });

    setAmount(amount);
  }, [cart]);

  return (
    <>
      <section className=" hidden lg:grid grid-cols-[75%_calc(25%-2rem)] gap-8  mt-2 ">
        {cart.length ? (
          <>
            <div
              data-testid="cartcontainer"
              className=" h-[calc(100vh-9.25rem)] overflow-y-scroll scroll scrollbar-thin scrollbar-thumb-gray-400 pr-2"
            >
              {cart.map((item: IRewards, index: number) => {
                return (
                  <SingleCartItem
                    key={index}
                    {...item}
                    removeFromCart={() =>
                      removeRewardFromCart(
                        item.title,
                        item.image,
                        item.points,
                        item.sku
                      )
                    }
                  />
                );
              })}
            </div>
            <div className=" h-[calc(100vh-9.25rem)] overflow-hidden">
              <div className=" mt-2  h-[calc(100%-3rem)] overflow-hidden   border rounded  flex flex-col  ">
                <div className="flex-1  h-[calc(100%-3.5rem)]">
                  <div className="px-4 py-2">
                    <h2 className="text-black text-base font-medium">
                      Product Summary
                    </h2>
                  </div>
                  <div className="w-full h-[0.05rem] bg-[#D9D9D9] mb-1 "></div>
                  <div className="border-b-2 pb-2 border-dashed   max-h-[70%] overflow-y-scroll scroll scrollbar-thin scrollbar-thumb-gray-400 ">
                    {cart.map((item: IRewards, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex items-center justify-between px-4 space-x-10 py-1"
                        >
                          <p className="text-sm text-black capitalize flex-1 truncate ">
                            {item.title}
                          </p>
                          <p className="text-sm text-black flex items-center space-x-1">
                            <span className="h-4 w-5">
                              <img
                                src="/threecoins.svg"
                                alt="threecoins"
                                className="h-full w-full"
                              />
                            </span>
                            {item.points}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-between px-4 py-2">
                    <h2 className="text-[#7A8496] uppercase font-medium">
                      Total points BURN
                    </h2>
                    <p className="text-black font-medium flex items-center space-x-1 ">
                      {" "}
                      <span className="h-6 w-6">
                        <img
                          src="/threecoins.svg"
                          alt="threecoins"
                          className="h-full w-full"
                        />
                      </span>{" "}
                      {amount}
                    </p>
                  </div>
                </div>

                {balance === 0 && (
                  <div className={` flex items-center justify-center px-4`}>
                    <button
                      onClick={handleCheckout}
                      className="mb-4 bg-[#e47e09] text-white w-full text-center py-2 rounded text-base"
                    >
                      Checkout Now
                    </button>
                  </div>
                )}
              </div>
              <div className=" px-5 py-3  flex items-center justify-center">
                <h1 className="font-medium  text-filterText text-xs flex items-center space-x-1 w-max whitespace-nowrap">
                  Powered by
                  <AccoladezLogo className="h-5 ml-1" />
                </h1>
              </div>
            </div>
          </>
        ) : (
          <div className=" h-[calc(100vh-9.25rem)]  col-span-full flex flex-col items-center justify-center overflow-x-hidden">
            <div className=" relative  w-full flex justify-center">
              <img
                src="/newemptycart.png"
                alt="emptycart"
                className="h-80 w-64 md:w-96  block ml-40"
              />
            </div>
            <div className=" w-full flex flex-col justify-center items-center md:ml-10 ">
              <h3 className="text-lg mt-2 font-medium ">
                Fill your cart with Reward
              </h3>
              <button
                onClick={() => {
                  navigate(routes.products)
                }}
                className="text-center rounded px-3 py-2 bg-[#6ABD00] text-white mt-2"
              >
                Select Reward
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default CartOrders;
