import React, { FC, useEffect } from "react";

interface IModal {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  onClose?: () => void | boolean;
}
const Modal: FC<IModal> = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    window.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose?.();
      }
    });
  });

  if (isOpen) {
    return (
      isOpen && (
        <div
          onClick={onClose}
          className={` fixed fixedhandled  h-screen w-screen top-0 left-0 bg-black/20 flex items-center justify-center z-50 ${
            isOpen
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=" -translate-y-10  md:translate-y-0 rounded-lg overflow-hidden z-[130]"
          >
            {children}
          </div>
        </div>
      )
    );
  }
  return null;
};

export default Modal;
