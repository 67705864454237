import React, { Dispatch, FC, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context'
import { useWindowSize } from '../../packages/hooks/useWindowSize'
import { IFilter } from '../../packages/interface/rewards'
import { routes } from '../../packages/utils/routes'
import { AppDispatch } from '../../store'
import { updateFilter } from '../../store/filters/filterSlice'
import { RewardCard, UpgradeRewardCard } from '../reward'

const item = {
  sku: "Hs123",
  brand: "Bajaj",
  title: "Apple i13 ultra max pro",
  image: "/mens.jpg",
  description: "hello world",
  points: 1999
}

interface IRewardsShowcaseSection {
  title: string,
  randomRewards: any[],
  filters: { brands: number[], categories: number[] }
  isBrandsSection?: boolean,
  addToCart: (title: string, image: string, points: number, sku: string) => void,
  removeFromCart: (title: string, image: string, points: number, sku: string) => void,
  addCartArr: string[],
  id: string,
  trending: any,
  balance: number
}

const RewardsShowcaseSection: FC<IRewardsShowcaseSection> = ({ trending, title, randomRewards, filters, isBrandsSection, addToCart, addCartArr, removeFromCart, id, balance }) => {
  const { rewardsLoading, setIsModal, setModalData, setUpdateFilters } = useAppContext()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const windowSize = useWindowSize()
  const rewards = (windowSize && windowSize?.width >= 1280) ? randomRewards.slice(0, 6) : windowSize?.width >= 1024 ? randomRewards.slice(0, 5) : randomRewards.slice(0, 4)


  return (
    <section id={id} className=' px-4 mt-8 '>
      <div className='flex justify-between items-center border-b border-[#999999] py-2 '>
        <h2 className='capitalize text-black text-lg font-medium  w-[70%]'>
          {title}
          <span>{trending && trending[0]?.name}</span>
        </h2>
        <div>
          <button
            onClick={() => {
              if (isBrandsSection) {
                if (filters.brands[0] === 121) {
                  dispatch(updateFilter({ brands: [64], categories: [] }))

                } else {
                  dispatch(updateFilter({ brands: [...filters.brands], categories: [] }))
                }
              } else {
                if (filters.categories[0] === 11) {
                  dispatch(updateFilter({ brands: [], categories: [14] }))
                } else {
                  dispatch(updateFilter({ brands: [], categories: [...filters.categories] }))
                }
              }
              navigate(routes.products)
            }}
            className='capitalize text-white bg-black rounded text-sm px-4 py-1 w-max'>View All </button>
        </div>

      </div>
      <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6  gap-4 pt-3'>
        {
          rewards.map((item: {
            sku: string;
            id: number;
            brand: string;
            title: string;
            image: string;
            description: string;
            points: number;
          }, index: number) => {
            if (item.points > balance) {
              return <UpgradeRewardCard
                key={index} {...item}
                setModalData={(e: any) => setModalData(e)}
                openModal={setIsModal} />
            }

            return <RewardCard
              key={index}
              {...item}
              setModalData={(e: any) => setModalData(e)}
              openModal={setIsModal}
              addToCart={() =>
                addToCart(
                  item.title,
                  item.image,
                  item.points,
                  item.sku
                )
              }
              removeFromCart={() =>
                removeFromCart(
                  item.title,
                  item.image,
                  item.points,
                  item.sku
                )
              }
              isRewardLoading={(rewardsLoading.status && rewardsLoading.sku === item.sku)}
              isChecked={addCartArr.includes(item.sku)}
            />
          })
        }
      </div>
    </section>
  )
}

export default RewardsShowcaseSection