import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { Tracker } from "../../lib/sentry";
import { updateRewardInCart } from "../../packages/api/cart";
import { IRewards } from "../../packages/interface/rewards";
import { routes } from "../../packages/utils/routes";
import { RootState } from "../../store";
import { RewardCard } from "../reward";
interface ICartOrder {
  removeRewardFromCart: any;
}
const MobileCartOrder: FC<ICartOrder> = ({

  removeRewardFromCart,
}) => {
  const tracker = new Tracker();
  const navigate = useNavigate()
  const {
    setNotification,
    setNotificationData,
    rewardsLoading,
    setRewardsLoading,
  } = useAppContext();

  const {cart} = useSelector((state:RootState) => state.cart)

  return (
    <section
      className={` grid grid-cols-2 md:grid-cols-4 lg:hidden  pb-20  gap-4  h-auto `}
    >
      {cart.length ? (
        cart.map((item: IRewards, index: number) => {
          return (
            <RewardCard
              key={index}
              {...item}
              cartCard
              removeFromCart={() =>
                removeRewardFromCart(
                  item.title,
                  item.image,
                  item.points,
                  item.sku
                )
              }
              isRewardLoading={
                rewardsLoading.status && rewardsLoading.sku === item.sku
              }
            />
          );
        })
      ) : (
        <div className="col-span-full flex flex-col items-center justify-center overflow-x-hidden">
          <div className=" relative  w-full flex justify-center">
            <img
              src="/newemptycart.png"
              alt="emptycart"
              className="h-60 w-60 md:w-96  block ml-20"
            />
          </div>
          <div className=" w-full flex flex-col justify-center items-center  ">
            <h3 className="text-lg mt-2 font-medium ">
              Fill your cart with Reward
            </h3>
            <button
              onClick={() => {
                navigate(routes.products) 
              }}
              className="text-center rounded px-3 py-2 bg-[#6ABD00] text-white mt-2"
            >
              Select Reward
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default MobileCartOrder;

// ${balance === 0 ? "h-[calc(100vh+1.5rem-14.625rem)]  md:h-[calc(100vh+1.5rem-12rem)] " : "h-[calc(100vh+1.5rem-12rem)] "}
