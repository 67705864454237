import React, { ChangeEventHandler, FC, useEffect, useId, useRef,memo } from "react";
import { capitalize } from "../services/index"
interface ICheckbox {
  name: string
  bid?: number
  cid?: number
  sid?: string
  type: string
  isChecked?: any
  handleChange: any
}

const CheckBox: FC<ICheckbox> = memo(({ name, cid, bid, sid, handleChange, type, isChecked }) => {

  const id = useId()
  const inputCheckRef: { current: any } = useRef()

  useEffect(() => {
    inputCheckRef.current.checked = isChecked;
    if ( inputCheckRef?.current?.checked) {
    }
  }, [isChecked])



  return (
    <div className="space-x-3 lg:space-x-2 flex items-center ">
      <input
        ref={inputCheckRef}
        type="checkbox"
        value={cid || bid || sid}
        name={type}
        id={id}
        onChange={handleChange}
        className={`checkboxx   accent-orange-500 invert-0`} />
      <label style={{ textTransform: 'capitalize' }} htmlFor={id} className="text-filterText text-sm lg:text-base capitalize  ">
        {capitalize(name)}
      </label>
    </div>
  );
});

export default CheckBox;
