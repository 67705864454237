import { FC } from "react";
import { useAppContext } from "../../context";
import Notification from "./Notification";

const NotificationContainer: FC = () => {
  const { notificationData, setNotificationData } = useAppContext();

  const removeNoty = (id: string) => {
    const newNotificationArr = notificationData.filter((item: any) => item.id !== id)
    setNotificationData(newNotificationArr)
  }


  return (
    <div className="fixed fixedhandled top-0 right-0  space-y-3 mt-10 z-[500]">
      {notificationData.length > 0 &&
        notificationData?.map((item: any, index: number) => {

          return <Notification key={item.id} {...item} removeNoty={removeNoty} type={item.msg === "Reward Added" ? "add" : "remove"} />;
        })}
    </div>
  );
};

export default NotificationContainer;
