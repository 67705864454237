export const brands = [
    {
        name:"Adidas"
    },
    {
        name:"Reebok"
    },
    {
        name:"Puma"
    },
    {
        name:"Nike"
    },
    {
        name:"Puma"
    },
    {
        name:"Adidas"
    },
    {
        name:"Adidas"
    },
    {
        name:"Nike"
    },
]

export const category = [
    {
        name:"men"
    },
    {
        name:"women"
    },
    {
        name:"electronics"
    },
    {
        name:"wood"
    },
    {
        name:"shoes"
    },
]
export const sortHighLowData = [
    {
        name:"Low to High",
        sid:"points"
    },
    {
        name:"High to Low",
        sid:"-points"
    }
   
]