import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cart/cartSlice"
import userReducer from "./user/userSlice"
import rewardReducer from "./rewards/rewardSlice"
import filterReducer from "./filters/filterSlice"


export const store = configureStore({
    reducer:{
        cart:cartReducer,
        user:userReducer,
        reward:rewardReducer,
        filter:filterReducer
    }
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch