import React, { memo } from 'react'

const AccoladezLogo = memo((props: { className: string }) => {
  return (
    <div className={`${props.className}`}>
      <div className='h-6'>
        <img src="/logo.svg" alt="Myaccoladez" className='w-full h-full' />
      </div>
      <p className='text-blue-800 font-bold font-[16px]'>MyAccoladez</p>
    </div>
  )
})

export default AccoladezLogo