import axios, { AxiosError } from "axios";

const baseURL: string = process.env.REACT_APP_BASE_URL || "";
const API_KEY: string = "APKH0AMIHAM3W99YI8I77F";
const API_SECRET: string = "btb7gvhkyhuxegzq65386brxjhchacpxpq";
export const getAllRewards = async (
  token: string,
  page: number = 1,
  filters?: {}
) => {
  try {
    const response = await axios.post(
      `${baseURL}/client/store/reward?page=${page}`,
      { ...filters },
      {
        headers: { "r-token": token },
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    if (error instanceof AxiosError && error?.response) {
      throw error;
    } else {
      throw Error("Internal Server Error");
    }
  }
};

export const getAllFilters = async (
  token: string,
  query?: {
    category?: string;
    brand?: string;
  }
) => {
  try {
    const response = await axios.get(
      `${baseURL}/client/store/reward/filter${
        query
          ? query.category
            ? `?category=${query.category}`
            : query.brand
            ? `?brand=${query.brand}`
            : ""
          : ""
      }`,
      { headers: { "r-token": token }, withCredentials: true }
    );

    return response;
  } catch (error) {
    if (error instanceof AxiosError && error?.response) {
      throw error;
    } else {
      throw Error("internal error");
    }
  }
};
