import { createContext, useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getAllFilters } from "../packages/api/store";
import decode from "jwt-decode";
import { Spinner } from "../packages/design";
import InvalidTokenModal from "../components/InvalidTokenModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { setDecodedUser } from "../store/user/userSlice";

enum TokenError {
  TOKEN_NOT_PROVIDED = "TOKEN_NOT_PROVIDED",
  INVALID_TOKEN = "INVALID_TOKEN",
}

export interface TokenUser {
  user_id?: string;
  session_id?: string;
  range_points?: number;
  redirect_uri?: string;
  subscription?: string;
  duration?: string;
  type?: string;
  scopes?: string;
  exp?: number;
  iat?: number;
  iss?: string;
  aud?: string;
}

export interface IOriginalFilters {
  brands: IBrand[];
  category: ICategory[];
  points: IPoints;
}

export interface IBrand {
  bid: number;
  name: string;
}

export interface ICategory {
  cid: number;
  name: string;
}

export interface IPoints {
  max: number;
  min: number;
}

export const Appcontext = createContext<any | null>(null);

export const AppProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const [isOfferModal, setIsOfferModal] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    msg: string;
    isShow: boolean;
    data?: any;
  }>({ msg: "", isShow: false, data: null });
  const [search, setSearch] = useState<string | number>("");
  const [notificationData, setNotificationData] = useState<any>([]);
  const [rewardsLoading, setRewardsLoading] = useState<{
    status: boolean;
    sku: string;
  }>({ status: false, sku: "" });
  const [isShowSearchbar, setIsShowSearchbar] = useState<boolean>(false);
  const [isShowReedemActiveModal, setIsShowReedemActiveModal] =
    useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [isMobileReward, setIsMobileReward] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [searchParams] = useSearchParams();

  const [tokenState, setTokenState] = useState<string | TokenError | undefined>(
    undefined
  );

  const [originalPointsRange, setOriginalPointsRange] = useState<any>({
    points: { max: 11996, min: 780 },
  });
  const [isSmallDevices, setSmallDevices] = useState<boolean>(false);

  const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
  const [originalFilters, setOriginalFilters] = useState<IOriginalFilters>();
  const [isRandomRewardLoading, setIsRandomRewardLoading] =
    useState<boolean>(true);

  const [updateFilters, setUpdateFilters] = useState<{ brands?: number[], categories?: number[] }>({
    brands: [],
    categories: [],
  })

  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!tokenState) {
      const token = searchParams.get("token");
      setTokenState(token?.toString());
    }
  }, []);

  useEffect(() => {
    const widthChecker = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", widthChecker);
    if (width <= 768) {
      setIsMobileReward(true);
    }

    if (width <= 640) {
      setSmallDevices(true);
    }

    return () => window.removeEventListener("resize", widthChecker);
  }, [width]);

  const loadFilters = () => {
    getAllFilters(tokenState ?? "")
      .then((response: any) => {
        const newFiltersData = response?.data.data.data;
        setOriginalFilters(newFiltersData);
        setOriginalPointsRange(newFiltersData.points);
        setIsFilterLoaded(true);
      })
      .catch((error: any) => {
        setTokenState(TokenError.INVALID_TOKEN);
      });
  };

  const decodeTokenUserData = (token: string) => {
    if (token != TokenError.TOKEN_NOT_PROVIDED) {
      try {
        const user = decode(token);
        if (user) {
          // setDecodedUser(user);
          dispatch(setDecodedUser(user))
          return true;
        } else {
          setTokenState(TokenError.INVALID_TOKEN);
          return false;
        }
      } catch (error) {
        setTokenState(TokenError.INVALID_TOKEN);
        return false;
      }
    }
  };

  useEffect(() => {
    if (tokenState) {
      const isTokenDecodable = decodeTokenUserData(tokenState);
      if (isTokenDecodable) {
        loadFilters();
      }
    }
  }, [tokenState]);

  useEffect(() => {
    const newUrl = `?token=${tokenState}`;
    const isQuery = location.search;

    if (!isQuery) {
      //  window.history.pushState(null,"",newUrl)
      window.history.replaceState(null, "", newUrl);
    }
  }, [tokenState, location]);

  if (isFilterLoaded) {
    return (
      <Appcontext.Provider
        value={{
          updateFilters, setUpdateFilters,
          isRandomRewardLoading,
          setIsRandomRewardLoading,
          token: tokenState,
          isSmallDevices,
          originalPointsRange,
          isMobileReward,
          containerHeight,
          setContainerHeight,
          isShowReedemActiveModal,
          setIsShowReedemActiveModal,
          isShowSearchbar,
          setIsShowSearchbar,
          rewardsLoading,
          setRewardsLoading,
          notificationData,
          setNotificationData,
          search,
          setSearch,
          setModalData,
          setIsModal,
          isModal,
          modalData,
          setIsOfferModal,
          isOfferModal,
          notification,
          setNotification,
          originalFilters,
        }}
      >
        {props.children}
      </Appcontext.Provider>
    );
  } else {
    if (
      tokenState == TokenError.INVALID_TOKEN ||
      tokenState == TokenError.TOKEN_NOT_PROVIDED
    ) {
      return (
        <InvalidTokenModal
          message="Invalid Token"
        ></InvalidTokenModal>
      );
    } else {
      return (
        <div className="flex items-center justify-center h-screen w-screen ">
          <div className="w-16 h-16">
            <Spinner className="fill-[#F24E00]" />
          </div>
        </div>
      );
    }
  }
};

export const useAppContext = () => {
  return useContext(Appcontext);
};
