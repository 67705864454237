import { brands } from './../../packages/utils/filter';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from '../../packages/interface/rewards';


export interface IFilterState {
    filters:IFilter
}

const initialState:IFilterState = {
    filters:{brands:[],categories:[]}
}

export const filterSlice = createSlice({
    name:"filter",
    initialState,
    reducers:{
        setFilters:(state,action:PayloadAction<any>) => {
            state.filters = {...state.filters,...action.payload}
        },
        updateFilter:(state,action:PayloadAction<any>) => {
            state.filters = action.payload
        }
    }

})


export const {setFilters,updateFilter} = filterSlice.actions
export default filterSlice.reducer