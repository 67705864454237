import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { CheckBox, Spinner } from "../../packages/design";
import { sortHighLowData } from "../../packages/utils/filter";
import { BiSort } from "react-icons/bi";
import { BsFilterRight } from "react-icons/bs";
import Rheostat from "rheostat";
import "rheostat/initialize";
import { useAppContext } from "../../context";
import RadioButton from "../../packages/design/RadioButton";
import RheostatComp from "../RheostatComp";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FaFilter } from 'react-icons/fa';
import { Condition } from "../../packages/utils/condition";
interface IFilter {
  setCurrentShortType: Dispatch<SetStateAction<string>>
  isFiltersOn: boolean
  filterType: "filter" | "sort" | null
  setIsFiltersOn: Dispatch<SetStateAction<boolean>>
  setFilterType: Dispatch<SetStateAction<"filter" | "sort" | null>>
  filtersData: any;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  clearFilters: (type: string) => void;
  maxValue: number;
  minValue: number;
  setMaxValue: Dispatch<SetStateAction<number>>;
  setMinValue: Dispatch<SetStateAction<number>>;
  applyMobileFilters: (fa: any, fp: any) => void;
  sort: string;
  filterValueChangeLoading: boolean;
}

const filterDataType = ["Brands", "Categories", "Budget"];
const Filter: FC<IFilter> = ({
  setCurrentShortType,
  isFiltersOn,
  filterType,
  setIsFiltersOn,
  setFilterType,
  filtersData,
  handleChange,
  clearFilters,
  maxValue,
  minValue,
  setMaxValue,
  setMinValue,
  applyMobileFilters,
  sort,
  filterValueChangeLoading,
}) => {
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [showCount, setShowCount] = useState(10);
  const { setContainerHeight, originalPointsRange } = useAppContext();
  const { filters } = useSelector((state: RootState) => state.filter)
  const [mobileFilters, setMobileFilters] = useState<{
    brands: string[];
    categories: string[];
  }>({
    brands: [],
    categories: [],
  });
  const [min, setMin] = useState<number>(minValue);
  const [max, setMax] = useState<number>(maxValue);

  const [mobileMinValue, setMobileMinValue] = useState<number>(
    originalPointsRange.min
  );
  const [mobileMaxValue, setMobileMaxValue] = useState<number>(
    originalPointsRange.max
  );
  const [filterTypeActiveNumber, setFilterTypeActiveNumber] =
    useState<number>(0);
  const filterRef: { current: any } = useRef();


  const handleMobileFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (e.target.checked) {
      if (name === "brands") {
        const newBrands = [...mobileFilters.brands, value];
        setMobileFilters({ ...mobileFilters, brands: newBrands });
      }

      if (name === "category") {
        const newCategory = [...mobileFilters.categories, value];
        setMobileFilters({ ...mobileFilters, categories: newCategory });
      }
    } else {
      if (name === "brands") {
        const newBrands = mobileFilters.brands.filter(
          (item: any) => item !== value
        );
        setMobileFilters({ ...mobileFilters, brands: newBrands });
      }
      if (name === "category") {
        const newCategory = mobileFilters.categories.filter(
          (item: any) => item !== value
        );
        setMobileFilters({ ...mobileFilters, categories: newCategory });
      }
    }
  }, []);

  const clearMobileFilter = () => {
    const newFilters = {
      brands: [],
      categories: [],
    };
    setMobileFilters(newFilters);
    applyMobileFilters(newFilters, {
      mobileMinValue: originalPointsRange?.min,
      mobileMaxValue: originalPointsRange?.max,
    });

    setMobileMinValue(originalPointsRange?.min);
    setMobileMaxValue(originalPointsRange?.max);
  };

  const handleMobileSortChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setIsFiltersOn(false);
    setFilterType(null);
  }, []);
  useEffect(() => {
    if ((filters?.brands?.length === 0) && (filters?.categories?.length === 0) && (minValue === filtersData?.points?.min) &&
      (maxValue === filtersData?.points?.max) && sort === null) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [filters, minValue, maxValue, sort])


  return (
    <>
      {filterValueChangeLoading ? (
        <section className="hidden  lg:flex h-full  border border-[#D9D9D9] items-center justify-center">
          <div className="h-8 w-8">
            <Spinner className="fill-[#F24E00]" />
          </div>
        </section>
      ) : (
        <section
          ref={filterRef}
          className={`h-full drop-shadow hidden lg:block  border-[#D9D9D9]  py-2 lg:py-0 fixed fixedhandled lg:static w-full bottom-0 shadow-md  lg:w-auto lg:shadow-none left-0 right-0 z-10  bg-white `}
        >
          <form action="" className="">
            <div className="flex items-center justify-between lg:block pt-3 pb-2 pr-5 md:pr-14  lg:px-2  ">
              {/* Header  */}
              <div className="hidden  lg:flex justify-between items-center  ">
                <div className="flex items-center text-lg space-x-2">
                  {/* <FontAwesomeIcon icon={faFilter} /> */}
                  <FaFilter size={16} />
                  <h3>Filter</h3>
                </div>
                <button
                  data-testid="desktopclearall"
                  disabled={disableButton}
                  type={"button"}
                  onClick={() => { clearFilters("all"); setCurrentShortType('-points') }}
                  className={`${filters?.brands?.length === 0 &&
                    filters?.categories?.length === 0 &&
                    minValue === filtersData?.points?.min &&
                    maxValue === filtersData?.points?.max &&
                    sort === null
                    ? "text-white bg-[#183FA5]/30 px-4 py-1 rounded text-sm disabled:opacity-50"
                    : "text-white bg-[#183FA5] px-4 py-1 rounded text-sm disabled:opacity-50"
                    } uppercase  text-sm font-bold`}
                >
                  CLEAR
                </button>
              </div>

              <hr className="text-black my-3" />
              {/* Brands  */}
              <div className=" space-y-1 ">
                <div
                  onClick={() => {
                    setIsFiltersOn(true);
                  }}
                  className="flex justify-between items-center  lg:mb-2"
                >
                  <h2 className="uppercase text-sm font-semibold text-filterHeading">
                    Brands{" "}
                    <span className="text-xs text-gray-400">
                      ({filtersData?.brands?.length})
                    </span>
                  </h2>
                  <IoIosArrowDown className="lg:hidden ml-1" />
                </div>
                <div
                  className={` hidden lg:grid  scroll overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400`}
                >
                  {filtersData?.brands?.slice(0, showCount).map(
                    (item: { bid: number; name: string }, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          {...item}
                          handleChange={handleChange}
                          type="brands"
                          isChecked={filters.brands.includes(
                            item.bid.toString()
                          ) || (filtersData?.brands.length < 2)}
                        />
                      );
                    }
                  )}
                  <Condition
                    onClick={() => setShowCount(filtersData?.brands.length)}
                    className="text-sm text-[#183FA5] w-full cursor-pointer"
                    condition={filtersData?.brands.length > 10 && showCount === 10}
                  >
                    ...view <span>{filtersData?.brands.length - 10}</span> more
                  </Condition>
                  <Condition condition={10 !== filtersData?.brands.length} >
                    <Condition
                      onClick={() => setShowCount(10)}
                      className="text-sm text-[#183FA5] w-full cursor-pointer"
                      condition={filtersData?.brands.length > 10 && showCount !== 10}
                    >
                      view less
                    </Condition>
                  </Condition>
                </div>
              </div>

              <hr className="text-black my-3" />
              {/* Category  */}
              <div className="space-y-1">
                <div
                  onClick={() => {
                    setIsFiltersOn(true);
                    // setFilterType("category");
                  }}
                  className="flex justify-between items-center lg:mb-2"
                >
                  <h2 className="uppercase text-sm font-semibold text-filterHeading ">
                    Categories{" "}
                    <span className="text-xs text-gray-400">
                      ({filtersData?.category?.length})
                    </span>
                  </h2>
                  <IoIosArrowDown className="lg:hidden ml-1" />

                </div>
                <div className="hidden lg:grid scroll overflow-scroll scrollbar-thin scrollbar-thumb-gray-400">
                  {filtersData?.category?.map(
                    (item: { name: string; cid: number }, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          {...item}
                          handleChange={handleChange}
                          type="category"
                          isChecked={filters.categories.includes(
                            item.cid.toString()
                          ) || (filtersData?.category.length < 2)}
                        />
                      );
                    }
                  )}
                </div>
              </div>

              <hr className="text-black my-3" />
              {/* Points Range  */}
              <div className="space-y-1">
                <div
                  onClick={() => {
                    setIsFiltersOn(true);
                    // setFilterType("range");
                  }}
                  className="flex justify-between items-center  lg:mb-3"
                >
                  <h2 className="uppercase text-sm font-semibold text-filterHeading w-max flex ">
                    Budget
                  </h2>
                  <IoIosArrowDown className="lg:hidden ml-1" />
                </div>

                {/* *********************** */}
                <div className=" items-center text-sm font-medium text-filterHeading hidden lg:flex">
                  <span>Min</span>
                  <input
                    type="number"
                    value={minValue}
                    onChange={(e: any) => setMinValue(e.target.value)}
                    name=""
                    id=""
                    className="outline-none border border-filterText w-12 xl:w-16 pl-1 ml-2 "
                  />
                  <div className="border border-black w-3 h-0 mx-2"></div>
                  <span>Max</span>
                  <input
                    type="number"
                    value={maxValue}
                    onChange={(e: any) => setMaxValue(e.target.value)}
                    name=""
                    id=""
                    className="outline-none border border-filterText w-12 xl:w-16 pl-1 ml-2 "
                  />
                </div>
                <RheostatComp
                  min={originalPointsRange?.min}
                  max={originalPointsRange?.max}
                  values={[minValue, maxValue]}
                  setMinValue={setMinValue}
                  setMaxValue={setMaxValue}
                />
                {/* <div className="">
                  <Rheostat
                    min={originalPointsRange?.min}
                    max={originalPointsRange?.max}
                    values={[minValue, maxValue]}
                    onSliderDragEnd={() => {}}
                    onChange={(sliderState) => {
                      setMinValue(sliderState.values[0]);
                      setMaxValue(sliderState.values[1]);
                    }}
                  />
                </div> */}
              </div>

              {/* <hr className="text-black my-3" /> */}
            </div>
          </form>
        </section>
      )
      }
      {/* for mobile responsive  */}

      <form
        action=""
        className={`${isFiltersOn
          ? " fixed fixedhandled top-0 bottom-0 left-0 right-0 z-50  lg:hidden "
          : "hidden"
          }`}
      >
        <div className="bg-black opacity-10 fixed fixedhandled top-0 bottom-0 left-0 right-0"></div>
        {/* sort  */}
        {filterType === "sort" && (
          <div className="bg-white  fixed fixedhandled left-0 right-0 bottom-0  px-4 py-2 ">
            <div className="flex justify-between items-center  ">
              <h2 className="uppercase text-sm font-medium text-filterHeading">
                sort by
              </h2>
              <AiOutlinePlus
                onClick={() => {
                  setIsFiltersOn(false);
                  setFilterType(null);
                }}
                className=" text-xl rotate-45"
              />
            </div>
            <hr className="text-black my-3" />
            <div className=" h-[10vh] space-y-3 text-sm  ">
              {sortHighLowData.map(
                (item: { name: string; sid: string }, index: number) => {
                  return (
                    <RadioButton
                      key={index}
                      {...item}
                      handleChange={handleMobileSortChange}
                      sort={sort}
                    />
                  );
                }
              )}
            </div>
          </div>
        )}

        {/* ********\\\\\\\filter/////// ********* */}
        {filterType === "filter" && (
          <div className="fixed fixedhandled top-0 bottom-0 left-0 w-full  bg-white ">
            {/* filter header  */}
            <div className="uppercase font-medium flex items-center justify-between  py-2 px-3 shadow-sm ">
              <p className="text-sm">Filters</p>
              <button
                onClick={() => {
                  clearMobileFilter();
                  setIsFiltersOn(false);
                  setFilterType(null);
                }}
                className="text-base text-orangeColor "
              >
                clear all
              </button>
            </div>
            {/* filter body  */}

            <div className="  grid grid-cols-3 h-full  overflow-y-hidden ">
              <div className="">
                {filterDataType.map((item: string, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setFilterTypeActiveNumber(index)}
                      className={`${filterTypeActiveNumber === index
                        ? "bg-white"
                        : "bg-[#f5f5f6]"
                        } px-4 py-3 `}
                    >
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>

              <div className="col-span-2  py-2 px-2   overflow-y-scroll">
                {filterTypeActiveNumber === 0 && (
                  <div className="grid md:grid-cols-2 gap-y-1  max-h-[90%]  pb-10 overflow-y-scroll scrollbar-none">
                    {filtersData?.brands?.map(
                      (item: { bid: number; name: string }, index: number) => {
                        return (
                          <div key={index}>
                            <CheckBox
                              {...item}
                              handleChange={handleMobileFilterChange}
                              type="brands"
                              isChecked={mobileFilters.brands.includes(
                                item.bid.toString()
                              ) || (filtersData?.brands.length < 2)}
                            />
                            {index !== filtersData?.brands.length - 1 && (
                              <hr className="my-[0.95rem]" />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {filterTypeActiveNumber === 1 && (
                  <div className="grid md:grid-cols-2 gap-y-1  overflow-scroll scrollbar-none">
                    {filtersData?.category?.map(
                      (item: { name: string; cid: number }, index: number) => {
                        return (
                          <div key={index} className="">
                            <CheckBox
                              {...item}
                              handleChange={handleMobileFilterChange}
                              type="category"
                              isChecked={mobileFilters.categories.includes(item.cid.toString()) || (filtersData?.category.length < 2)}
                            />
                            {index !== filtersData?.category.length - 1 && (
                              <hr className="my-[0.95rem]" />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

                {filterTypeActiveNumber === 2 && (
                  <div className=" mt-2 ">
                    <div className=" items-center text-filterHeading justify-center flex ">
                      <span>Min</span>
                      <input
                        type="number"
                        value={mobileMinValue}
                        onChange={(e: any) => setMobileMinValue(e.target.value)}
                        name=""
                        id=""
                        className="outline-none border border-filterText w-14  pl-1 ml-2"
                      />
                      <div className="border border-black w-3 h-0 mx-2"></div>
                      <span>Max</span>
                      <input
                        type="number"
                        value={mobileMaxValue}
                        onChange={(e: any) => setMobileMaxValue(e.target.value)}
                        name=""
                        id=""
                        className="outline-none border border-filterText w-14  pl-1 ml-2"
                      />
                    </div>
                    <RheostatComp
                      min={originalPointsRange?.min}
                      max={originalPointsRange?.max}
                      values={[mobileMinValue, mobileMaxValue]}
                      setMinValue={setMobileMinValue}
                      setMaxValue={setMobileMaxValue} />

                  </div>
                )}
              </div>
            </div>
            <section className=" fixed fixedhandled z-[220] bottom-0 right-0 w-full flex items-center justify-around py-3 font-medium bg-white">
              <div
                onClick={() => {
                  setIsFiltersOn(false);
                  setFilterType(null);
                  setFilterTypeActiveNumber(0);
                }}
                className="flex items-center space-x-2 "
              >
                <p className="uppercase text-sm">Close</p>
              </div>
              <div
                onClick={() => {
                  applyMobileFilters(mobileFilters, {
                    mobileMinValue,
                    mobileMaxValue,
                  });
                  setIsFiltersOn(false);
                  setFilterType(null);
                }}
                className="flex items-center space-x-2"
              >
                <p className="uppercase text-sm text-orangeColor">Apply</p>
              </div>
            </section>
          </div>
        )}
      </form>
      {/* ************* */}
      {
        !filterType && (
          <section className="border-t  bg-white z-[120] lg:hidden fixed fixedhandled bottom-0 right-0 w-full flex items-center justify-evenly py-3 font-medium ">
            <div
              onClick={() => {
                setIsFiltersOn(true);
                setFilterType("sort");
              }}
              className="flex items-center space-x-2 "
            >
              <BiSort className="text-base" />
              <p className="uppercase text-sm">Sort</p>
              {
                sort !== null &&
                <div className="h-[0.375rem] w-[0.375rem] bg-[#ff0000] rounded-full"></div>
              }
            </div>
            <div
              onClick={() => {
                setIsFiltersOn(true);
                setFilterType("filter");
              }}
              className="flex items-center space-x-2"
            >
              <BsFilterRight className="text-base" />
              <p className="uppercase text-sm">Filters</p>
              {
                (filters?.brands?.length > 0 ||
                  filters?.categories?.length > 0
                ) &&
                <div className="h-[0.375rem] w-[0.375rem] bg-[#ff0000] rounded-full"></div>
              }
            </div>
          </section>
        )
      }
    </>
  );
};

export default Filter;
