import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { TokenUser } from "../../context";
import { Modal } from "../../packages/design";
import { RootState } from "../../store";

interface ICongratsModal {
  isOpen: boolean;
  isClose: () => any;
}

const CongratulationModal: FC<ICongratsModal> = ({
  isOpen,
  isClose,
}) => {

  const {decodedUser,orderToken} = useSelector((state:RootState) => state.user)

  return (
    <Modal isOpen={isOpen} onClose={isClose}>
      <section className="w-[90vw]  md:w-[50vw] lg:w-[40vw]  h-[55vh]  xl:w-[30vw]  3xl:w-[20vw] rounded-md">
        <div className="bg-[#6ABD00] text-white flex items-center justify-center h-20 md:h-20 lg:h-20 xl:h-28 relative">
          <h2 className="text-3xl font-medium z-10 relative ">
            Congratulations
            <img
              src="congratscrown.png"
              alt="congratscrown"
              className="w-24 h-12 absolute -top-8 -left-8 "
            />
          </h2>
          <img
            src="threecoins.svg"
            alt="threecoins"
            className=" absolute -right-5  rotate-[20deg] h-28 w-28 opacity-20"
          />
          <img
            src="threecoins.svg"
            alt="threecoins"
            className=" absolute -left-5  rotate-[20deg] h-28 w-28 opacity-20"
          />
        </div>

        <div className=" flex flex-col items-center justify-center bg-white py-5 px-10  space-y-3">
          <p className="text-xs h-[calc(100%-9.5rem)] md:h-[calc(100%-10.5rem)] xl:h-[calc(100%-13.5rem)] text-center">
            Its not always rewards coincide with smart decisions, but with
            Accoladez, rest assured every attempt is rewarded and every
            transaction validated with benefits
          </p>

          <div className="bg-black w-52 rounded-md px-2">
            <div
              onClick={() => {
                const newUrl =
                decodedUser.redirect_uri + "?token=" + orderToken;
                window.location.href = newUrl;
              }}
              className={` group text-sm text-white font-medium w-full rounded-md flex items-center justify-center p-2 overflow-hidden cursor-pointer relative `}
            >
              <p>Continue Purchase</p>
            </div>
          </div>
          <button onClick={isClose} className="text-sm font-medium">
            Cancel
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default CongratulationModal;
