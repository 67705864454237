import { FC } from "react"
import { IconProps } from "."

export const CartBagIcon: FC<IconProps> = ({
  color = "currentColor",
  size = 1
}) => (
  <svg width={24 * size} height={32 * size} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4192 0.0957594C9.07948 0.338873 7.87613 0.987131 6.93409 1.97313C5.72523 3.23844 5.15035 4.74181 5.15035 6.6379V7.41711L4.33688 7.45085C3.67673 7.47828 3.4388 7.51699 3.07449 7.65631C2.02315 8.05847 1.21545 8.93417 0.920607 9.99169C0.821927 10.3456 0.724775 12.1041 0.22872 22.521C-0.0903621 29.2226 -0.0930186 29.0328 0.331583 29.9269C0.765481 30.8406 1.73123 31.5895 2.74638 31.7997C2.99115 31.8504 5.67065 31.8722 11.5452 31.8715C20.8507 31.8705 20.4272 31.8885 21.2477 31.4573C21.8291 31.1517 22.4066 30.5526 22.7072 29.943C23.1454 29.0547 23.1423 29.2492 22.822 22.521C22.3259 12.1041 22.2288 10.3456 22.1301 9.99169C21.982 9.46057 21.6976 8.96518 21.3035 8.55206C20.5596 7.77212 19.934 7.50577 18.7223 7.45291L17.9174 7.41778L17.8825 6.38051C17.8318 4.87223 17.5485 3.93497 16.8065 2.81974C15.4368 0.761216 12.8445 -0.344315 10.4192 0.0957594ZM12.4218 2.22209C13.6251 2.4739 14.7423 3.33831 15.2971 4.44676C15.6492 5.15027 15.7727 5.7306 15.7742 6.68797L15.7754 7.43504H11.5254H7.27535L7.27648 6.68797C7.27794 5.73836 7.40106 5.15545 7.74936 4.44902C8.58475 2.75433 10.5314 1.82657 12.4218 2.22209ZM7.05814 10.9522C7.76304 11.2721 7.85475 12.2516 7.22482 12.7321C6.91324 12.9697 6.32667 12.9907 6.03508 12.7746C5.4432 12.3361 5.37846 11.5866 5.89111 11.108C6.21777 10.803 6.61328 10.7502 7.05814 10.9522ZM17.0254 11.0012C17.6675 11.4767 17.663 12.295 17.0156 12.7746C16.724 12.9907 16.1375 12.9697 15.8259 12.7321C15.2122 12.264 15.2808 11.3228 15.9552 10.9568C16.2674 10.7872 16.7643 10.8079 17.0254 11.0012Z" fill={color} />
  </svg>
)