import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { Tracker } from "../../lib/sentry";
import { updateRewardInCart } from "../../packages/api/cart";
import { Modal } from "../../packages/design";
import { IRewards } from "../../packages/interface/rewards";
import { routes } from "../../packages/utils/routes";
import { AppDispatch, RootState } from "../../store";
import { setCart } from "../../store/cart/cartSlice";
import { setIsRemainingRewardModal } from "../../store/rewards/rewardSlice";
import RewardCard from "./RewardCard";

interface IRemainingRewardModal {
  isOpen: boolean;
  isClose: () => any
  balance: number;
  token: string;
  setBalance: Dispatch<SetStateAction<number>>;
}

const RemainingPointsRewardIdea: FC<IRemainingRewardModal> = ({
  isClose,
  isOpen,
  balance,
  token,
  setBalance,
}) => {
  const tracker = new Tracker();
  const navigate = useNavigate()

  const { cart } = useSelector((state: RootState) => state.cart)
  const { remainingReward } = useSelector((state: RootState) => state.reward)



  const dispatch = useDispatch<AppDispatch>()

  const { setNotificationData, setRewardsLoading } = useAppContext();
  const [newRewardsBalance, setNewRewardBalance] = useState<number>(balance);
  const [localCart, setLocalCart] = useState<IRewards | undefined>();

  const modifiedRemainingReward = remainingReward.map((item: any) => {
    return { ...item, points: newRewardsBalance || balance }
  })


  useEffect(() => {
    setLocalCart(undefined);
  }, []);

  const addRewardsToCart = (
    title: string,
    image: string,
    balance: number,
    sku: string
  ) => {
    setRewardsLoading({ status: true, sku });
    updateRewardInCart(token, sku, "add")
      .then((res: any) => {
        if (res.status === 200) {
          tracker.rewardAction(sku, "add");
          tracker.selectionOfXtraReward(true);
          const reward = modifiedRemainingReward.find((item: any) => item.sku === sku);
          const newReward = { ...reward, remainingpoints: balance };
          dispatch(setCart([newReward]))

          setNewRewardBalance(balance);
          setBalance((prevBal: number) => {
            return prevBal - balance;
          });
          let notyData = {
            id: sku,
            msg: "Reward Added",
            isShow: true,
            data: { title, image, balance },
          };
          setNotificationData((prev: any) => {
            if (prev.length > 0) {
              return [...prev, notyData];
            }
            return [notyData];
          });
          setRewardsLoading({ status: false, sku });

          // setIsRemainingRewardModal(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 403) {
          setRewardsLoading({ status: false, sku });
        }
      });
  };

  const onContinue = () => {
    if (!!localCart) {
      addRewardsToCart(
        localCart.title,
        localCart.image,
        newRewardsBalance,
        localCart.sku
      );
      isClose();
      dispatch(setIsRemainingRewardModal(false))
      navigate(routes.cart)
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={isClose}>
      <div className=" relative w-[95vw] h-[80vh] xl:h-[70vh]  lg:w-[75vw] xl:w-[70vw] m-auto  bg-white px-3 xl:px-8 py-3 space-y-3 z-[1000]">
        <h2 className="text-base w-[90%]">
          Select Any Product To Continue Your Redemption
          <span className="text-[#C0BCBC] ml-2">(Pick any 1)</span>
        </h2>
        {/* h-[60vh] lg:h-[55vh] */}
        <AiOutlinePlus
          onClick={isClose}
          className="top-1 md:block rotate-45 text-2xl md;top-3 text-black absolute right-3  xl:top-0 cursor-pointer"
        />
        <div className="grid  grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-5 gap-4 md:gap-5 lg:gap-5 h-[calc(100%-7rem)]  md:h-[calc(100%-5.5rem)] overflow-y-scroll  lg:scrollbar-thin scrollbar-thumb-gray-400 ">
          {modifiedRemainingReward.map((item: IRewards, index) => {

            return (
              <RewardCard
                addToCart={() => setLocalCart(item)}
                key={index}
                {...item}
                isChecked={localCart?.sku === item.sku}
                isRemainingRewardCard={localCart === item}
              />
            );
          })}
        </div>
        <div className=" flex  justify-end space-x-3 2xl:space-x-7 ">
          {/* <div onClick={isClose} className='  px-5 py-1 shadow-md flex items-center justify-center bg-red-500 rounded-md'>
           <button className='text-white '>Close</button>
            </div> */}
          <button
            disabled={!localCart}
            onClick={onContinue}
            className="w-52 disabled:opacity-50 flex items-center justify-center "
          >
            <div
              className={` group bg-[#ffd814] text-black font-medium w-full rounded-md flex items-center justify-center p-2 overflow-hidden cursor-pointer relative  `}
            >
              {/* <div className="translate-x-[100%]  opacity-0 group-hover:opacity-100 group-hover:translate-x-[200%] transition-all duration-1000 absolute">
                <img src="bluefourarrow.svg" alt="bluefourarrow" className="h-8" />
              </div> */}

              <p className="capitalize px-2 text-center">Continue</p>
            </div>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemainingPointsRewardIdea;
