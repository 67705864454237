import React, { Dispatch, FC, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { IFilter } from "../../packages/interface/rewards";
import { routes } from "../../packages/utils/routes";
import { AppDispatch } from "../../store";
import { updateFilter } from "../../store/filters/filterSlice";

interface ISingleCategoryCard {
  title: string;
  data: any[];
  isBrandcard?: boolean
  setFilters: Dispatch<SetStateAction<IFilter>>
  id: string
}

const SingleCategoryCard: FC<ISingleCategoryCard> = ({
  title,
  data,
  isBrandcard, setFilters, id
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  return (
    <div id={id} className="border bg-white shadow h-max p-4 lg:p-2 xl:p-4">
      <h2 className="text-black text-base mb-2 font-bold line-clamp-2">{title}</h2>
      <div className="grid grid-cols-2 gap-4 lg:gap-2 xl:gap-4">
        {data?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={`h-36 lg:h-28 xl:h-36 w-auto cursor-pointer border bg-[#f4f4f4] `}
              onClick={() => {
                if (isBrandcard) {
                  dispatch(updateFilter({ brands: [item.bid], categories: [] }))
                } else {
                  dispatch(updateFilter({ brands: [], categories: [item.cid] }))
                }
                navigate(routes.products)
              }}
            >

              <img
                src={item.image}
                alt={item.title}
                className="w-full h-full "
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleCategoryCard;
