import React, { FC, memo } from "react";
import {
  AiFillDelete,
  AiOutlineCheck,
} from "react-icons/ai";
import { IRewardCard } from "../../packages/interface/rewards";
import { Spinner } from "../../packages/design";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { CartBagIcon } from "../../packages/design/icons";

const RewardCard: FC<IRewardCard> = memo(({
  sku,
  brand,
  title,
  image,
  description,
  points,
  setModalData,
  openModal,
  addToCart,
  cartCard,
  removeFromCart,
  isOfferCard,
  isChecked,
  isRewardLoading,
  isRemainingRewardCard
}) => {

  return (
    <div
      className={`${isChecked ? "" : ""
        } shadow   w-full h-max rounded relative `}
    >
      {isOfferCard && (
        <div className="absolute h-10 w-8 right-5 bg-[#f8f8f8]">
          <img src="tagsgroup.png" alt="tagsgroup" className="w-full h-full" />
        </div>
      )}
      <div
        data-testid="rewardcard"
        onClick={() => {
          if (setModalData && openModal) {
            setModalData({
              brand,
              title,
              image,
              description,
              points,
              sku,
              type: "normalCard",
              isChecked,
            });
            openModal(true);
          }
        }}
        className={`lg:cursor-pointer`}
      >
        <div className="h-[70%] w-auto rounded-t">
          <AspectRatio.Root ratio={1}>
            <img src={image} alt={title} className="w-full h-full rounded-t " />
          </AspectRatio.Root>
        </div>
        <div className="mb-2 h-16 p-2">
          <div className="">
            <h2 className="text-[#7A8496] uppercase text-xs font-medium">{brand}</h2>
          </div>
          <div className="space-y-1">
            <h2 className="text-sm text-black max-text-line-2 font-medium">{title}</h2>
          </div>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className={`py-1 px-2 h-[calc(70%-4rem)] group transition-all cursor-auto w-full relative  flex justify-between`}
        >
          <div className="text-base flex items-center space-x-2 pointer-events-none">
            <img src="coinsthree.svg" alt="threecoins" className="h-6 w-6" />
            <h3 className="font-medium">{points}</h3>
          </div>
          <button
            data-testid="rewardbutton"
            className=" bg-[#ffd814] absolute bottom-0 right-0 h-8 w-8 rounded-br rounded-tl flex items-center justify-center group-hover:rounded-tl-none group-hover:rounded-bl group-hover:w-full duration-300 text-black overflow-hidden "
            onClick={() => {
              if (cartCard || isChecked) {
                if (!isRewardLoading && removeFromCart) {
                  removeFromCart();
                }
              } else {
                if (!isRewardLoading && addToCart) {
                  if (!isChecked) {
                    addToCart();
                  }
                }
              }
            }}
          >
            <div className="h-8 w-8 flex items-center justify-center opacity-100 group-hover:opacity-0 overflow-hidden transition-all duration-200 ">
              {isRewardLoading ? (
                <div className="h-8 w-8 pl-2 pt-2  ">
                  <Spinner className=" fill-black" />
                </div>
              ) : isChecked ? (
                <AiOutlineCheck className="text-xl text-black" />
              ) : cartCard ? (
                <AiFillDelete className="text-2xl text-black" />
              ) : (
                <CartBagIcon size={0.5} />
              )}
            </div>

            <div className=" space-x-2 text-black w-max flex items-center absolute left-full group-hover:left-[calc(50%-7.6rem/2)] transition-all">
              <div className="h-8 w-8 flex items-center justify-center transition-all">
                {isRemainingRewardCard ? (
                  <AiOutlineCheck className="text-xl text-black" />
                ) : isChecked ? (
                  <AiFillDelete className="text-xl text-black" />
                ) : cartCard ? (
                  <AiFillDelete className="text-xl text-black" />
                ) : (
                  <CartBagIcon size={0.5} />
                )}
                { }
              </div>
              {isRemainingRewardCard
                ? "Added"
                : isChecked
                  ? "Remove"
                  : cartCard
                    ? "Remove "
                    : "Add to Cart"}
              { }
            </div>
          </button>
        </div>
      </div>
    </div>
  );
});

export default RewardCard;
