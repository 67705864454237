import { Steps } from "intro.js-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  CongratulationModal,
  Navbar,
  NotificationContainer,
} from "./components/shared";
import { useAppContext } from "./context";
import { getUserCart } from "./packages/api/cart";
import { getAllRewards } from "./packages/api/store";
import {
  desktopIntroSteps,
  mobileIntroData,
} from "./packages/utils/introjsData";
import { routes } from "./packages/utils/routes";
import Cart from "./pages/Cart";
import Category from "./pages/Category";
import Home from "./pages/Home";
import { AppDispatch, RootState } from "./store";
import { setCart } from "./store/cart/cartSlice";
import {
  setIsCongratsModal,
  setIsReedemActive,
  setIsRemainingPointsLoading,
  setIsRemainingRewardModal,
  setRemainingReward,
} from "./store/rewards/rewardSlice";
import { setError } from "./store/user/userSlice";
import { RemainingPointsRewardIdea } from "./components/reward";
import ShowRewardActiveModal from "./components/ShowRewardActiveModal";
import { IRewards } from "./packages/interface/rewards";
import InvalidTokenModal from "./components/InvalidTokenModal";
import { Spinner } from "./packages/design";
import 'intro.js/introjs.css';
import Footer from "./components/shared/Footer";

function App() {
  const { decodedUser, error } = useSelector((state: RootState) => state.user);
  const { cart } = useSelector((state: RootState) => state.cart);
  const { isRemainingRewwardModal, isCongratsModal, isRemainingPointsLoading } = useSelector(
    (state: RootState) => state.reward
  );
  const dispatch = useDispatch<AppDispatch>();

  const { token, isRandomRewardLoading, isSmallDevices, setIsShowReedemActiveModal } = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [balance, setBalance] = useState<number>(decodedUser?.range_points);
  const [stepsData, setStepsData] = useState<any>(desktopIntroSteps);
  const [isInitialCartLoading, setIsInitialCartLoading] =
    useState<boolean>(false);





  // intro js  start
  const onExit = () => {
    window.localStorage.setItem("doneTour", "yes");
    return { stepsEnabled: false };
  };

  const onComplete = () => {
    window.localStorage.setItem("doneTour", "yes");
  };
  // intro js funct ends

  const getUserCartData = async () => {
    setIsInitialCartLoading(true);
    getUserCart(token)
      .then((response: any) => {
        const cartData = response?.data.data.data;
        dispatch(setCart(cartData));

        if (cartData.length > 0) {
          let newBal = 0;
          cartData.map((items: any) => {
            newBal += items.points;
          });
          setBalance((prevBal: number) => {
            return prevBal - newBal;
          });
        }
        setIsInitialCartLoading(false);
      })
      .catch((error: any) => {
        if (error?.response.status === 401) {
          dispatch(setError({ msg: "Invalid Token", isError: true }));
          setIsInitialCartLoading(false);
        } else {
          setError({ msg: "Something went wrong Try again", isError: true });
          setIsInitialCartLoading(false);
        }
      });
  };

  // get user intial cart data
  useEffect(() => {
    let isCancelled = false;
    if (isCancelled === false && !(cart.length < 0)) {
      getUserCartData();
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const reedemNow = useCallback(() => {
    if (balance && balance !== 0) {
      if (balance && balance < 300) {
        dispatch(setIsRemainingPointsLoading(true));
        getAllRewards(token, 1, { zero_value: 1 }).then((res: any) => {
          if (res.status === 200) {
            const newRemainingReward = res.data.data.results;
            dispatch(setRemainingReward(newRemainingReward));
            dispatch(setIsRemainingPointsLoading(false));
          }
        });
      } else if (balance && balance >= 300 && balance < 780) {
        dispatch(setIsRemainingPointsLoading(true));
        getAllRewards(token, 1, { zero_value: 300 }).then((res: any) => {
          if (res.status === 200) {
            const newRemainingReward = res.data.data.results;
            dispatch(setRemainingReward(newRemainingReward));
            dispatch(setIsRemainingPointsLoading(false));
          }
        });
      }
    } else {
      navigate(routes.cart);
    }
  }, [balance]);

  const checkRemainingRewards = async () => {
    if (typeof balance !== "undefined") {
      if (balance > 0) {
        const points = {
          points_range: [0, balance],
        };
        let checkRewardArray: any = [];
        let checkCartArray: any = [];
        getAllRewards(token, 1, points)
          .then((response: any) => {
            if (response?.data || response?.data !== null) {
              const rewards: IRewards[] = response?.data?.data?.results || [];
              let newRewards = rewards.filter(
                (item: any) => balance === 780 ? true : item.points < balance
              );
              if (newRewards.length <= 0) {
                dispatch(setIsReedemActive(true));
                setIsShowReedemActiveModal(true);
              } else if (rewards.length > 0) {
                let ans: any[] = [];
                rewards.map((item: any) => {
                  checkRewardArray.push(item.sku);
                });

                cart.length > 0 &&
                  cart.map((item: any) => {
                    checkCartArray.push(item.sku);
                  });

                checkRewardArray.map((item: any) => {
                  ans.push(checkCartArray.includes(item));
                });

                if (ans.includes(false)) {
                  dispatch(setIsReedemActive(false));
                  setIsShowReedemActiveModal(false);
                } else {
                  dispatch(setIsReedemActive(true));
                  setIsShowReedemActiveModal(true);
                }
              }
            }
          })
          .catch((error: any) => {
            if (error?.response.status === 401) {
              dispatch(setError({ msg: "Invalid Token", isError: true }));
            } else {
              dispatch(
                setError({
                  msg: "Something went wrong Try again",
                  isError: true,
                })
              );
            }
          });
      } else {
        dispatch(setIsReedemActive(true));
        setIsShowReedemActiveModal(true);
      }
    }
  };

  useEffect(() => {
    checkRemainingRewards();
  }, [balance]);

  if (isRemainingPointsLoading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen ">
        <div className="w-16 h-16">
          <Spinner className="fill-[#F24E00]" />
        </div>
      </div>
    );
  }

  if (error.isError) {
    return <InvalidTokenModal message={error.msg} />;
  }

  return (
    <>
      <Navbar
        points={balance || 0}
        reedemNow={reedemNow}
      />
      <Routes>
        <Route
          path={routes.products}
          element={
            <Home
              balance={balance}
              setBalance={setBalance}
              reedemNow={reedemNow}
              isInitialCartLoading={isInitialCartLoading}
            />
          }
        />
        <Route
          path={routes.home}
          element={
            <Category
              balance={balance || 0}
              setBalance={setBalance}
            />
          }
        />
        <Route
          path={routes.cart}
          element={
            <Cart
              balance={balance || 0}
              setBalance={setBalance}
            />
          }
        />
      </Routes>

      {isRemainingRewwardModal && (
        <RemainingPointsRewardIdea
          token={token}
          setBalance={setBalance}
          balance={balance || 0}
          isOpen={isRemainingRewwardModal}
          isClose={() => dispatch(setIsRemainingRewardModal(false))}
        />
      )}

      <CongratulationModal
        isOpen={isCongratsModal}
        isClose={() => dispatch(setIsCongratsModal(false))}
      />

      {(pathname === "/" || pathname === "/products") && (
        <ShowRewardActiveModal balance={balance || 0} reedemNow={reedemNow} />
      )}

      <NotificationContainer />
      {window.localStorage.getItem("doneTour") === null && !isRandomRewardLoading && (
        <Steps
          options={{ nextToDone: true }}
          enabled={true}
          steps={isSmallDevices ? mobileIntroData : desktopIntroSteps}
          initialStep={0}
          onExit={onExit}
          onComplete={onComplete}
        />
      )}
      <Footer />
    </>
  );
}

export default App;
