import { ISessionData, UserAnalytics } from ".";

type TVisitedSite = () => string

class Tracker {
  private session_data!: ISessionData | undefined
  constructor(sessionData?: ISessionData) {
    this.session_data = sessionData
  }

  private userAnalytics: UserAnalytics = new UserAnalytics()

  // getting session details
  private session = this.userAnalytics.getSessionData(this.session_data || {
    aud: "",
    duration: "",
    session_id: "",
    subscription: "",
    user_id: ""
  })

  // user session data
  private sessionData = {
    sessionId: this.session.sessionId,
    subscription: this.session.subscription,
    subscriptionDuration: this.session.subscriptionDuration,
    userId: this.session.userId,
    userSubscriptionSegment: this.session.userSubscriptionSegment
  }

  // 1. User Visiting the Site after getting the token and returns event id
  visitedSite: TVisitedSite = () => {

    // setting user data in sentry to create event
    this.userAnalytics.setUserInSentry(this.session.sessionId, this.session.userIdSegment, this.session.userSubscriptionSegment)

    // creating event entry
    return this.userAnalytics.captureEventInSentry({
      ...this.sessionData,
      transactionName: 'User Visited',
      message: 'User Visited the site'
    })
  }

  // 2. User Add or Remove Reward to cart
  rewardAction = (sku: string, action: 'add' | 'remove') => {

    return this.userAnalytics.captureEventInSentry({
      ...this.sessionData,
      transactionName: `Reward ${action}ed`,
      message: `User ${action}ed ${sku} ${action === 'add' ? 'to' : 'from'} cart`
    })
  }

  // rewardRedeemed = () => {

  //   return this.userAnalytics.captureEventInSentry({
  //     ...this.sessionData,
  //     transactionName: `Reward Redeemed`,
  //     message: `User has redeemed the reward`
  //   })
  // }

  //3. Selection of Extra Reward
  selectionOfXtraReward = (status: boolean) => {
    return this.userAnalytics.captureEventInSentry({
      ...this.sessionData,
      transactionName: `Selection Reward ${status ? 'Available' : 'UnAvailable'}`,
      message: `User ${status ? 'had some points left to redeem' : 'had zero points so coninuing to order creation'}`
    })
  }

  //4. Order Generated
  orderGenerated = () => {

    return this.userAnalytics.captureEventInSentry({
      ...this.sessionData,
      transactionName: `Order created`,
      message: `User has created the order`
    })
  }
}

export default Tracker