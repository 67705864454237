import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useAppContext } from "../../context";
import { updateRewardInCart } from "../../packages/api/cart";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import parse from "html-react-parser";
import { Modal } from "../../packages/design";
import { IRewards } from "../../packages/interface/rewards";
// import ReactImageZoom from "react-image-zoom";
import htmlTagValidator from "html-tag-validator";
import { Tracker } from "../../lib/sentry";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setCart } from "../../store/cart/cartSlice";

interface CartDataModalProps {
  token: string;
  rewards: IRewards[];
  setBalance: (arg: (prevBal: number) => number) => void;
}

const CartDataModal: FC<CartDataModalProps> = memo(
  ({ token, rewards, setBalance }) => {
    const { isModal, setIsModal, modalData, setNotificationData } =
      useAppContext();

    const { cart } = useSelector((state: RootState) => state.cart)
    const dispatch = useDispatch<AppDispatch>()

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedImageNumber, setSelectedImageNumber] = useState<number>(0);
    const [showZoomedImage, setShowZoomedImage] = useState<boolean>(false);

    const conatinerRef: { current: any } = useRef();
    const lensReF: { current: any } = useRef();
    const imageRef: { current: any } = useRef();
    const resultRef: { current: any } = useRef();
    const [addCartArr, setAddCart] = useState<string[]>([]);

    const tracker = new Tracker();


    useEffect(() => {
      let newAddCartArr: any[] = [];
      cart.map((item: any) => {
        newAddCartArr.push(item.sku);
      });
      setAddCart([...newAddCartArr]);
    }, [cart]);

    useEffect(() => {
      let newSelectedImageData = {
        url: modalData?.image,
        name: modalData?.title,
      };
      setSelectedImage(newSelectedImageData);
    }, []);

    const insideMouseMove = (e: any) => {
      const containerRect = conatinerRef?.current?.getBoundingClientRect();
      const lensRect = lensReF?.current?.getBoundingClientRect();
      const imageRect = imageRef?.current?.getBoundingClientRect();

      // let x = e.clientX - (containerRect.left + 50 - lensRect.width / 60);
      // let y = e.clientY - (containerRect.top + 50 - lensRect.height / 60);
      let x = e.clientX - imageRect.left - lensRect.width / 2;
      let y = e.clientY - imageRect.top - lensRect.height / 2;
      let minX = 0;
      let minY = 0;
      let maxX = containerRect.width - lensRect.width;
      let maxY = containerRect.height - lensRect.height;

      if (x <= minX) {
        x = minX;
      } else if (x >= maxX) {
        x = maxX;
      }

      if (y <= minY) {
        y = minY;
      } else if (y >= maxY) {
        y = maxY;
      }

      return { x, y };
    };

    const handleMouseMove = (e: any) => {
      if (showZoomedImage) {
        const lensRect = lensReF?.current?.getBoundingClientRect();
        const resultRect = resultRef?.current?.getBoundingClientRect();
        const imageRect = imageRef?.current?.getBoundingClientRect();

        let { x, y } = insideMouseMove(e);
        let fx = resultRect.width / lensRect.width;
        let fy = resultRect.height / lensRect.height;

        lensReF.current.style.left = `${x}px`;
        lensReF.current.style.top = `${y}px`;

        resultRef.current.style.backgroundImage = `url(${selectedImage?.url})`;
        resultRef.current.style.backgroundSize = `${imageRect.width * fx}px ${imageRect.height * fy
          }px`;

        resultRef.current.style.backgroundPosition = `-${x * fx}px -${y * fy
          }px`;
      }
    };

    const images = [
      {
        url: modalData?.image,
        name: modalData?.title,
      }
    ];

    const addRewardsToCart = useCallback(
      (title: string, image: string, points: number, sku: string) => {
        updateRewardInCart(token, sku, "add")
          .then((res: any) => {
            if (res.status === 200) {
              tracker.rewardAction(sku, "add");
              const reward = rewards.find((item: any) => item.sku === sku);
              const newCart = [...cart, reward]
              dispatch(setCart(newCart))
              setBalance((prevBal: number) => {
                return prevBal - points;
              });
              let notyData = {
                id: sku,
                msg: "Reward Added",
                isShow: true,
                data: { title, image, points },
              };
              setNotificationData((prev: any) => {
                if (prev.length > 0) {
                  return [notyData, ...prev];
                }
                return [notyData];
              });
              setAddCart((prev: any) => {
                return [...prev, sku];
              });
            }
          })
          .catch((e) => {
            if (e.response.status === 403) {
              setNotificationData({
                msg: "Reward Already Added",
                isShow: true,
                data: { title, image, points },
              });
            }
          });
      },
      [rewards]
    );

    return (
      <Modal isOpen={isModal} onClose={() => setIsModal(false)}>
        <section
          className="small:h-[82vh] h-[80vh] w-[95vw] md:w-[60vw] md:h-[80vh] lg:w-[70vw] xl:w-[60vw] lg:h-[50vh] xl:h-[50vh] 
             lg:grid grid-cols-[35%_65%] gap-4 rounded bg-white p-4 relative"
        >
          {/* ************** cross btn start *********** */}
          <AiOutlinePlus
            onClick={() => setIsModal(false)}
            className="hidden lg:block absolute right-3 top-3 rotate-45 text-xl cursor-pointer"
          />
          {/* ************** cross btn end *********** */}

          {/* ****************** left side ************ */}
          <div className=" small:h-[45%] lg:h-full w-full  flex flex-col overflow-hidden">
            <div
              ref={conatinerRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setShowZoomedImage(false)}
              className=" h-full  lg:h-[calc(100%-3.5rem)] w-full  flex-1  overflow-hidden "
            >
              <img
                ref={imageRef}
                src={selectedImage?.url}
                alt={modalData?.title}
                onMouseEnter={() => setShowZoomedImage(true)}
                className="h-56   small:h-32 lg:h-full w-full overflow-hidden"
              />
              {showZoomedImage && (
                <>
                  <div
                    ref={lensReF}
                    className={`${showZoomedImage ? "block" : "hidden"
                      } hidden lg:block bg-black opacity-20 absolute  h-28 w-28 top-0 left-0`}
                  ></div>
                  <div
                    ref={resultRef}
                    className={`${showZoomedImage ? "block" : "hidden"
                      } hidden lg:block absolute   top-0 bottom-0 right-0 left-[36%] pointer-events-none`}
                  ></div>
                </>
              )}
            </div>
            {/* lg:h-[22rem] xl:h-80 h-60  */}
            <div className="flex  mt-2 items-center justify-between  ">
              <div className="flex space-x-2  ">
                {images.map((item: any, index: number) => {
                  return (
                    <img
                      onClick={() => {
                        setSelectedImage(item);
                        setSelectedImageNumber(index);
                      }}
                      key={index}
                      src={item?.url}
                      alt={item?.name}
                      className={`${index === selectedImageNumber
                        ? "border border-black"
                        : ""
                        } block w-12 h-12 `}
                    />
                  );
                })}
              </div>
              <div className=" text-xl flex items-center lg:hidden  space-x-2 z-10 mb-2 mr-2">
                <img
                  src="threecoins.svg"
                  alt="threecoins"
                  className="h-8 w-9"
                />
                <p> {modalData?.points}</p>
              </div>
            </div>
          </div>

          {/* ************* right side *************** */}
          <div className=" small:h-[55%] overflow-hidden text-black px-2 flex flex-col  justify-between h-[calc(100%-19rem)]  lg:h-full mt-2 lg:mt-0 ">
            {/* ***********upper part *********** */}
            {/* md:h-[80%] */}
            <div className=" h-[calc(100%-3rem)] flex-1">
              <p className="text-sm uppercase font-semibold text-[#7A8496]">{modalData?.brand}</p>
              <h2 className="text-xl font-medium ">{modalData?.title}</h2>
              <div className="hidden text-xl lg:flex items-center  space-x-2 z-10 mb-2 ">
                <img
                  src="threecoins.svg"
                  alt="threecoins"
                  className="h-8 w-9"
                />
                <p> {modalData?.points}</p>
              </div>
              <div className="mt-2 text-xs text-textColor h-[70%] md:h-[75%] lg:h-[75%] overflow-y-scroll list-item scrollbar-thin scrollbar-thumb-gray-400">
                <div className="prose prose-li:list-disc prose-li:text-sm">
                  {modalData?.description &&
                    parse(modalData?.description)
                  }
                </div>
              </div>
            </div>
            {/* *************lower part *********** */}
            <div className="flex justify-between lg:justify-end ">
              <button
                onClick={() => {
                  setIsModal(false);
                }}
                className=" lg:hidden py-2 px-4 text-black bg-[#D9D9D9]/20 rounded "
              >
                Close
              </button>
              {modalData.isChecked === false &&
                modalData?.type !== "upgradeCard" && (
                  <button
                    onClick={() => {
                      addRewardsToCart(
                        modalData?.title,
                        modalData?.image,
                        modalData?.points,
                        modalData?.sku
                      );
                      setIsModal(false);
                    }}
                    className="text-black  bg-[#ffd814] rounded px-3 py-2 w-32 lg:w-52 mt-2"
                  >
                    Add to cart
                  </button>
                )}
            </div>
          </div>
        </section>
      </Modal>
    );
  }
);

export default CartDataModal;
