import axios from "axios";
const baseURL: string = process.env.REACT_APP_BASE_URL || "";

export const updateRewardInCart = async (
  token: string,
  sku: string,
  action: string
) => {
  try {
    const response = await axios.patch(
      `${baseURL}/client/store/reward/${sku}/${action}`,
      {},
      {
        headers: {
          "r-token": token,
        },
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserCart = async (token: string) => {
  try {
    const response = await axios.get(`${baseURL}/client/user/cart`, {
      headers: { "r-token": token },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createOrder = async (token: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/auth/client/order/token`,
      {
        headers: {
          "r-token": token,
        },
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
