import React, { FC, memo, useId } from 'react'
interface IRadioButton {
  name: string
  sid?: string
  handleChange: any
  sort: any
}

const RadioButton: FC<IRadioButton> = memo(({ name, sid, handleChange, sort }) => {

  const id = useId()
  return (
    <div className="flex items-center space-x-2 ">
      <input
        checked={sort === sid}
        type="radio"
        name="sort"
        value={sid}
        id={id}
        className="accent-black b "
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        className="text-sm text-filterText "
      >
        {name}
      </label>
    </div>
  )
})

export default RadioButton