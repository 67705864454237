import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect } from "react";
import Rheostat from "rheostat";
import 'rheostat/initialize';

interface IRheostatComp {
  min: number;
  max: number;
  values: number[];
  setMinValue: Dispatch<SetStateAction<number>>;
  setMaxValue: Dispatch<SetStateAction<number>>;
}

const RheostatComp: FC<IRheostatComp> =
  memo(({ min, max, values, setMinValue, setMaxValue }) => {
    return (
      <div className="">
        <Rheostat
          min={min}
          max={max}
          values={[values[0], values[1]]}
          onSliderDragEnd={() => { }}

          onChange={(sliderState) => {
            setMinValue(sliderState.values[0]);
            setMaxValue(sliderState.values[1]);
          }}
        />
      </div>
    );
  })
  ;

export default RheostatComp;
