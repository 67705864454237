import { category } from './../filter';

interface IRoutes {
    home: string,
    products:string,
    cart:string,
}

export const routes: IRoutes = {
    home: "/",
    products:"/products",
    cart:"/cart"
}