import React, { useState } from "react";
import { TiWarningOutline } from "react-icons/ti";
import { Modal } from "../packages/design";

const InvalidTokenModal = (props: { message: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const tryAgainClick = () => {
    window.history.back()
  };
  return (
    <Modal isOpen={isOpen}>
      <div className=" text-[#EB2227] bg-white w-[90vw] sm:w-[50vw] lg:w-[40vw]  xl:w-[30vw]  3xl:w-[20vw] flex  justify-center px-10 py-4">
        <div className="flex flex-col items-center   space-y-2">
          <TiWarningOutline className="text-5xl" />
          <h2 className="font-medium text-base text-black">{props.message}</h2>
          <div className="flex flex-col items-center space-y-3">
            <p className="text-black opacity-40 text-xs text-center ">
              Dear User, given token is invalid. Please use a new token and try again.
            </p>

            <button
              onClick={tryAgainClick}
              className="uppercase text-white text-sm px-3 py-2 rounded text-center w-max  bg-[#EB2227] "
            >
              Please try again
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvalidTokenModal;
