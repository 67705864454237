import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useAppContext } from "../../context";
import { RootState } from "../../store";


const CartHeader = () => {
  const [amount, setAmount] = useState<number>(0);
  const {cart} = useSelector((state:RootState) => state.cart)
  useEffect(() => {
    let amount = 0;
    cart.map((item: any) => {
      if (item.remainingpoints) {
        amount += item.remainingpoints;
      } else {
        amount += item.points;
      }
    });

    setAmount(amount);
  }, [cart]);



  return (
    <section className="my-2 fixed fixedhandled top-10 md:top-12 left-0 shadow-md flex justify-between items-center z-20 bg-white w-screen">
      <div className=" px-3  py-2   flex items-center space-x-2 ">
        <IoIosArrowRoundBack
          data-testid="backicon"
          onClick={() => {
            window.history.back() 
          }}
          className="text-4xl  cursor-pointer  "
        />
        <h1 className="font-medium text-lg ">
          My Cart
          {cart?.length > 0 && (
            <span className="text-sm text-gray-400 ml-2">({cart?.length})</span>
          )}
        </h1>
      </div>
      <div className=" flex lg:hidden items-center text-black  rounded pl-2 pr-4 space-x-1  ">
        <img src="/threecoins.svg" alt="threecoins" className="h-10 w-10" />
        <p data-testid="amount" className="font-medium w-max">
          {amount}
        </p>
      </div>
    </section>
  );
};

export default CartHeader;
