import React, { FC } from "react";
import { IRewards } from "../../packages/interface/rewards";
import parse from "html-react-parser";

interface ISingleCartItem {
  sku: string;
  brand: string;
  title: string;
  image: string;
  description: string;
  points: number;
  id?: string | number;
  removeFromCart: () => void;
}

const SingleCartItem: FC<ISingleCartItem> = ({
  sku,
  brand,
  title,
  image,
  description,
  points,
  id,
  removeFromCart,
}) => {
  return (
    <section className="border shadow my-2 flex rounded  mr-2 ">
      <div className="p-1 ">
        {/* image  */}
        <img src={image} alt={title} className="w-28 h-28" />
      </div>
      <div className="py-4 px-4 flex-1 space-y-1">
        <h2 className="uppercase text-sm text-[#7A8496] ">{brand}</h2>
        <h2 className="text-base capitalize font-medium">{title}</h2>
        <div className="text-xs text-[#7A8496] max-text-line-1">
          {sku === "MIL0067" ? <div></div> : (parse(description) || "")}
        </div>
        {/* title & name  */}
      </div>
      <div className="p-4 flex flex-col">
        <div className="flex space-x-2 flex-1">
          <img src="coinsthree.svg" alt="threecoins" className="h-6 w-6" />
          <h3 className="text-black font-medium text-base">{points}</h3>
        </div>
        {/* points & remove  */}
        <button
          onClick={removeFromCart}
          className="capitalize text-[#7A8496] text-sm hover:text-black"
        >
          remove
        </button>
      </div>
    </section>
  );
};

export default SingleCartItem;
