const Footer = () => {
    return <>
        <div className="w-full flex px-4 pb-2 justify-between items-center drop-shadow bg-gray-50">
            <div className="h-12 max-w-[5rem]">
                <img src="/hindu.png" alt="hindulogo" className="h-full w-full" draggable={false} />
            </div>
            <p className="font-bold font-[12px]"> Powered by Accoladez</p>
        </div>
    </>
}

export default Footer