import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CartDataModal } from "../components/cart";
import Banner from "../components/Categorypage/Banner";
import CategoryCard from "../components/Categorypage/CategoryCard";
import RewardsShowcaseSection from "../components/Categorypage/RewardsShowcaseSection";
import { IBrand, useAppContext } from "../context";
import { Tracker } from "../lib/sentry";
import { updateRewardInCart } from "../packages/api/cart";
import { getAllRewards } from "../packages/api/store";
import { Spinner } from "../packages/design";
import { routes } from "../packages/utils/routes";
import { AppDispatch, RootState } from "../store";
import { setCart, updateCart } from "../store/cart/cartSlice";

interface ICategory {
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>;
}

const Category: FC<ICategory> = ({ balance, setBalance }) => {
  const tracker = new Tracker();
  const navigate = useNavigate()

  const { cart } = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch<AppDispatch>();

  const {
    token,
    setRewardsLoading,
    setNotificationData,
    originalFilters,
    isRandomRewardLoading,
    setIsRandomRewardLoading,
    isModal,
  } = useAppContext();
  const [randomBrands, setRandomBrands] = useState<any[]>([]);
  const [randomCategory, setRandomCategory] = useState<any[]>([]);

  const randomRewards = randomBrands.concat(randomCategory);

  const [addCartArr, setAddCart] = useState<string[]>([]);
  const [randomFilters, setRandomFilters] = useState<{
    brands: number[];
    categories: number[];
  }>({ brands: [], categories: [] });
  const [trendingBrands, setTrendingBrands] = useState<
    { bid: number; name: string }[]
  >([{ bid: 0, name: "" }]);
  const [trendingCategory, setTrendingCategory] = useState<
    { cid: number; name: string }[]
  >([{ cid: 0, name: "" }]);

  const generateRandomFilters = useCallback(() => {
    const randomCategoryIndex = Math.floor(
      Math.random() * originalFilters.category.length
    );
    const randomBrandIndex = Math.floor(
      Math.random() * originalFilters.brands.length
    );

    if (
      randomCategoryIndex >= 0 &&
      randomBrandIndex >= 0 &&
      randomCategoryIndex < originalFilters.category.length &&
      randomBrandIndex < originalFilters.brands.length
    ) {
      const newRandomFilters = {
        brands: [originalFilters.brands[randomBrandIndex].bid],
        categories: [originalFilters.category[randomCategoryIndex].cid],
      };
      setRandomFilters(newRandomFilters);
    } else {
      setRandomFilters({ brands: [64], categories: [14] });
    }
  }, [originalFilters])

  useEffect(() => {
    generateRandomFilters();
  }, []);


  const getRandomRewards = async (
    token: string,
    page: number,
    pagefilters: { brands: number[]; categories: number[] }
  ) => {
    setIsRandomRewardLoading(true);
    try {
      if (pagefilters.brands.length > 0) {
        let brandNum =
          pagefilters.brands[0] === 121 ? 64 : pagefilters.brands[0];
        const res = await getAllRewards(token, page, {
          brands: [brandNum],
          categories: [],
        });
        const newRewards = res?.data.data.results;
        if (newRewards.length < 1) {
          generateRandomFilters();
          return;
        }
        setRandomBrands(newRewards);
      }
      if (pagefilters.categories.length > 0) {
        let catNum =
          pagefilters.categories[0] === 11 ? 14 : pagefilters.categories[0];
        const res = await getAllRewards(token, page, {
          brands: [],
          categories: [catNum],
        });
        const newRewards = res?.data.data.results;
        setRandomCategory(newRewards);
      }
    } catch (error) {
      throw error
    } finally {
      setIsRandomRewardLoading(false);
    }
  };

  useEffect(() => {
    if (
      randomFilters.brands.length > 0 &&
      randomFilters.categories.length > 0
    ) {
      getRandomRewards(token, 1, randomFilters);
    }
  }, [randomFilters]);

  useEffect(() => {
    const newTrendingBrand = originalFilters.brands.filter(
      (item: IBrand, index: number) => item.bid === randomFilters.brands[0]
    );
    if (newTrendingBrand[0]?.bid === 121) {
      const newData = [{ bid: 64, name: "Milton" }];
      setTrendingBrands(newData);
    } else {
      setTrendingBrands(newTrendingBrand);
    }
    const newTrendingCategory = originalFilters.category.filter(
      (item: any, index: number) => item.cid === randomFilters.categories[0]
    );
    if (newTrendingCategory[0]?.cid === 11) {
      const newData = [{ cid: 14, name: "Baby Products" }];
      setTrendingCategory(newData)
    } else {
      setTrendingCategory(newTrendingCategory);
    }

  }, [randomFilters]);

  useEffect(() => {
    let newAddCartArr: string[] = [];
    cart.forEach((item) => {
      if (item.sku) {
        newAddCartArr.push(item.sku);
      }
    });
    setAddCart([...newAddCartArr]);
  }, [cart]);

  const addRewardsToCart = useCallback(
    (title: string, image: string, points: number, sku: string) => {
      setRewardsLoading({ status: true, sku });
      updateRewardInCart(token, sku, "add")
        .then((res: any) => {
          if (res?.status === 200) {
            tracker.rewardAction(sku, "add");

            const reward = randomRewards.find((item: any) => item.sku === sku);

            dispatch(setCart([reward]));

            setBalance((prevBal: number) => {
              return prevBal - points;
            });
            let notyData = {
              id: sku,
              msg: "Reward Added",
              isShow: true,
              data: { title, image, points },
            };
            setNotificationData((prev: any) => {
              if (prev.length > 0) {
                return [notyData, ...prev];
              }
              return [notyData];
            });
            setAddCart((prev: any) => {
              return [...prev, sku];
            });
            setRewardsLoading({ status: false, sku });
          }
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            setNotificationData({
              msg: "Reward Already Added",
              isShow: true,
              data: { title, image, points },
            });
            setRewardsLoading({ status: false, sku });
          }
        });
    },
    [randomRewards]
  );

  const removeRewardFromCart = useCallback(
    (title: string, image: string, points: number, sku: string) => {
      setRewardsLoading({ status: true, sku });
      updateRewardInCart(token, sku, "remove")
        .then((res: any) => {
          if (res.status === 200) {
            tracker.rewardAction(sku, "remove");
            dispatch(updateCart(sku));
            setBalance((prevBal: number) => {
              return prevBal + points;
            });

            let notyData = {
              id: sku,
              msg: "Reward Removed",
              isShow: true,
              data: { title, image, points },
            };
            setNotificationData((prev: any) => {
              if (prev.length > 0) {
                return [notyData, ...prev];
              }
              return [notyData];
            });
            setRewardsLoading({ status: false, sku });
          }
        })
        .catch((e) => {
          setRewardsLoading({ status: false, sku });
        });
    },
    [cart]
  );

  if (isRandomRewardLoading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen ">
        <div className="w-16 h-16">
          <Spinner className="fill-[#F24E00]" />
        </div>
      </div>
    );
  }

  return (
    <>
      <section className="mt-16 pb-8 relative">
        <Banner />
        <CategoryCard />
        <div className="h-[8.5rem] sm:h-[13.5rem] md:h-[16.5rem] lg:h-[21rem] xl:h-[26.5rem] 2xl:h-[32.5rem] cursor-pointer mt-4" onClick={() => navigate(routes.products)}>
          <img src="/explore.jpg" alt="explore more banner" className="w-full h-full" />
        </div>
        <RewardsShowcaseSection
          filters={randomFilters}
          randomRewards={randomBrands}
          title="Trending In "
          isBrandsSection
          addToCart={(
            title: string,
            image: string,
            points: number,
            sku: string
          ) => addRewardsToCart(title, image, points, sku)}
          removeFromCart={(
            title: string,
            image: string,
            points: number,
            sku: string
          ) => removeRewardFromCart(title, image, points, sku)}
          addCartArr={addCartArr}
          id="trendingbrands"
          trending={trendingBrands}
          balance={balance}
        />

        <RewardsShowcaseSection
          filters={randomFilters}
          randomRewards={randomCategory}
          title="Best seller in "
          addToCart={(
            title: string,
            image: string,
            points: number,
            sku: string
          ) => addRewardsToCart(title, image, points, sku)}
          removeFromCart={(
            title: string,
            image: string,
            points: number,
            sku: string
          ) => removeRewardFromCart(title, image, points, sku)}
          addCartArr={addCartArr}
          id="trendingcategory"
          trending={trendingCategory}
          balance={balance}
        />
      </section>
      {isModal && (
        <CartDataModal
          token={token}
          rewards={randomRewards}
          setBalance={setBalance}
        />
      )}
    </>
  );
};

export default Category;
