import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const sliderData = ["/banner/AMandVIPbrandbanneraccoladez.jpg", "/banner/accoladezbanner1.jpg", "/banner/accoladezbanner2.jpg", "/banner/accoladezbanner3.jpg", "/banner/accoladezbanner4.jpg", "/banner/accoladezbanner5.jpg", "/banner/accoladezbanner6.jpg",];

const Banner = () => {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        slidesPerView="auto"
        spaceBetween={60}
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        className=""
      >
        {sliderData.map((item: any, index: number) => {
          return (
            <SwiperSlide key={index} className="swiper-slider ">
              <div className="h-[9rem] sm:h-[14.5rem] md:h-[18rem] lg:h-[22rem]   xl:h-[28.5rem] 1xl:h-[31.5rem] 2xl:h-[34.5rem]">
                <img src={item} alt={item} className="w-full  h-full" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Banner;
