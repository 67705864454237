import React, { FC, ReactElement } from "react";
interface TooltipProps {
  children: ReactElement<any, any> | ReactElement<any, any>[]
  tooltipText: string
  point?: 'left' | 'center' | 'right'
}
export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipText,
  point = 'left'
}) => {
  const tipRef = React.createRef<any>();
  function handleMouseEnter() {
    tipRef.current.style.opacity = 1;
    tipRef.current.style.marginTop = "1.5rem";
  }
  function handleMouseLeave() {
    tipRef.current.style.opacity = 0;
    tipRef.current.style.marginTop = "1.5rem";
  }
  return (
    <div
    data-testid="tooltipbox"
      className="relative flex items-center w-full"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`absolute whitespace-no-wrap bg-gradient-to-r text-[11px] from-black to-gray-700 text-white px-2 py-1 rounded flex items-center transition-all duration-150 w-max z-10 pointer-events-none  ${point === "right" ? 'right-0' : point === 'center' ? 'left-[calc(50%)]' : ''}`}
        style={{ bottom: "80%", opacity: 0 }}
        ref={tipRef}
      >
        <div
          className={`bg-black h-2 w-2 absolute ${point === "right" ? 'right-4' : point === 'center' ? 'left-auto right-auto' : ''}`}
          style={{ bottom: "-4px", transform: "rotate(45deg)" }}
        />
        {tooltipText}
      </div>
      {children}
    </div>
  )
} 