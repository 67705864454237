import { createElement, FC, HTMLAttributes } from "react";

interface ConditionProps extends HTMLAttributes<HTMLDivElement> {
    children: (JSX.Element | string | number)[] | JSX.Element | string;
    condition: boolean;
    element?: string;
}

export const Condition: FC<ConditionProps> = ({
    condition,
    children,
    element = "div",
    ...props
}) => {
    const returnEl = createElement(
        element,
        {
            ...props,
        },
        children
    );

    if (condition) {
        return returnEl;
    }

    return <></>;
};